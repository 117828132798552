'use client';

import dynamic from 'next/dynamic';
import { SessionProvider } from "next-auth/react";
import ClientOnly from "@/components/client-only";

// Use dynamic imports with ssr: false for components that use browser APIs
const GenerativeUIPage = dynamic(() => import("./generative_ui/generative-ui"), { ssr: false });
const Navbar = dynamic(() => import("@/components/navbar").then(mod => ({ default: mod.Navbar })), { ssr: false });

// This disables static generation for this page
export const config = {
  unstable_runtimeJS: true,
  unstable_JsPreload: false
};

export default function Home() {
  return (
    <SessionProvider>
      <div className="relative w-full">
        <ClientOnly>
          <Navbar />
          <GenerativeUIPage />
        </ClientOnly>
      </div>
    </SessionProvider>
  );
}
